.form {
    &__field {
        position: relative;
        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }
    &__input {
        width: 100%;
        height: 48px;
        font-size: 0.875rem;
        letter-spacing: 0.02em;
        color: #fff;
        padding: 0 16px;
        background: #181818;
        border: 1px solid #fff;
        border-radius: 3px;
        outline: none;
        appearance: none;
        transition: 300ms;
    }
    &__placeholder {
        position: absolute;
        top: 15px;
        left: 12px;
        color: #fff;
        font-size: 0.875rem;
        line-height: 1;
        letter-spacing: 0.02em;
        padding: 3px 4px;
        background: #181818;
        pointer-events: none;
        transition: 300ms;
        .form__field--focus &,
        .form__field--filled & {
            top: -9px;
            font-size: 0.75em;
        }
    }
    &__submit {
        width: 100%;
        height: 58px;
        margin-top: 35px;
        font-size: 1.3125em;
        line-height: 140%;
        text-transform: uppercase;
        letter-spacing: 0.15px;
        background: #fff;
        color: #181818;
        font-weight: 700;
        border: 2px solid #FFF;
        border-radius: 3px;
        outline: none;
        appearance: none;
        transition: 300ms;
        cursor: pointer;
        &:hover {
            background: #181818;
            color: #fff;
        }
    }
}

@media screen and (max-width: 900px) {
    .form {
        &__field:not(:last-child) {
            margin-bottom: 20px;
        }
        &__submit {
            margin-top: 20px;
            font-size: 1em;
            height: 50px;
        }
    }
}