html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

html,
body {
    min-height: 100%;
    background: #181818;
}

body {
    padding: 0;
    margin: 0 auto;
    min-height: 100vh;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    line-height: 1.375;
    color: #fff;
    position: relative;
    min-width: 300px;
    -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

p,
a,
img,
ol,
ul,
li {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

ol,
ul {
    list-style: none;
}

img {
    width: 100%;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}