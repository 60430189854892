.container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

.logo {
    width: 50vw;
    background: url(../../images/logos.png) right 0 / 1575px auto repeat, url(../../images/logos.png) right 119px / 1575px auto repeat;
}

.content_container {
    width: 50vw;
    padding: 50px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    max-width: 400px;
    margin: 0 auto;
    &__title {
        font-size: 2.125em;
        line-height: 1.2;
        letter-spacing: -0.015em;
        margin-bottom: 27px;
    }
    &__text {
        font-size: 1.3125em;
        line-height: 1.4;
        letter-spacing: 0.01em;
        margin-bottom: 50px;
    }
}

.message {
    text-align: center;
    &__text {
        font-size: 3em;
        line-height: 1.2;
        letter-spacing: -0.015em;
        span {
            position: relative;
            top: 2px;
            font-size: 0.7em;
        }
    }
}

@media screen and (max-width: 900px) {
    .container {
        align-items: flex-start;
    }
    .logo {
        width: 100%;
        height: 200px;
        background-size: 491px auto, 491px auto;
        background-position: right bottom -15px, right bottom -53px;
    }
    .content_container {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .content {
        display: block;
        &__title {
            font-size: 1.625em;
            line-height: 1.4;
            letter-spacing: 0;
        }
        &__text {
            font-size: 1em;
            letter-spacing: 0.03em;
            margin-bottom: 41px;
        }
    }
}